import { useEffect, useState } from 'react';
import { useResize } from '../hooks/useResize';
import { useLocation } from 'react-router-dom';
import { useScroll } from '../hooks/useScroll';
import Aos from 'aos';

function Header(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const size = useResize();
  const location = useLocation();
  const [currentMenu, setCurrentMenu] = useState('HOME');
  const [isScrollHeader, setIsScrollHeader] = useState(false);
  const { scrollY } = useScroll();
  const menuItems = [
    { id: 1, name: '웨이메이커', link: 'waymaker' },
    { id: 2, name: '메디컬웨이', link: 'medical' },
    { id: 3, name: '그로잉웨이', link: 'growing' },
    { id: 4, name: '문의', link: 'contact' },
  ];

  useEffect(() => {
    if (size.width < 1025) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [size]);

  useEffect(() => {
    setActiveIdx(props.activeIdx);
    if (props.activeIdx > 0) {
      setIsScrollHeader(true);
    } else {
      setIsScrollHeader(false);
    }
  }, [props]);

  const onClickGnbMove = (e) => {
    e.preventDefault();

    window.scrollTo({
      top: document.getElementById(e.currentTarget.dataset.target).offsetTop,
      behavior: 'smooth',
    });
    // target = document.getElementById()
  };

  useEffect(() => {
    // const visualHeight = document.getElementById('visual').offsetHeight;
    const headerHeight = document.getElementById('header').offsetHeight;
    console.log(scrollY);
    if (scrollY > 0) {
      setIsScrollHeader(true);
    } else {
      setIsScrollHeader(false);
    }
    // Aos.init();
  }, [scrollY]);

  const onMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header
      id='header'
      className={`fixed z-50 w-full h-auto px-6 py-5 ease-in duration-100  ${
        isScrollHeader ? 'bg-black/[.5]' : ''
      }`}
    >
      <div className='max-w-[1920px] flex justify-between mx-auto'>
        <h1 className='z-30 w-16 h-auto '>
          <a href='/'>
            <img
              className='w-full h-full'
              src={`${process.env.PUBLIC_URL}/assets/images/img_logo.svg`}
              alt=''
            />
          </a>
        </h1>
        {isMobile && (
          <a
            href='#contact'
            className='relative z-10 w-16 text-sm font-bold leading-9 text-center bg-white rounded-sm h-9'
            onClick={onMenuToggle}
          >
            문의
          </a>
        )}

        <ul className={`${isMobile ? 'hidden' : 'flex'}`}>
          {menuItems.map((item, idx) => (
            <li
              key={idx.toString()}
              className={!isMobile && idx > 0 ? 'md:ml-16' : 'ml-0'}
            >
              <button
                // href={item.link}
                data-target={item.link}
                onClick={(e) => onClickGnbMove(e)}
                className=' lg:text-white inline-block text-[1.3rem] font-bold md:font-semibold text-black  md:text-[1.13rem] leading-5 py-1 mb-6 lg:mb-0'
              >
                {item.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
}

export default Header;
