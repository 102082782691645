import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useResize } from '../hooks/useResize';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../swiper.css';
import emailjs from '@emailjs/browser';
import Aos from 'aos';

function Home(props) {
  const form = useRef();
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [part, setPart] = useState('');
  const [price, setPrice] = useState('');
  const [memo, setMemo] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const size = useResize();

  useEffect(() => {
    if (size.width < 1025) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [size]);

  const initForm = () => {
    setCompany('');
    setName('');
    setPhone('');
    setMemo('');
    setEmail('');
    setPart('');
    setPrice('');
    setIsAgree(false);
  };

  const onClickSend = (e) => {
    e.preventDefault();
    if (!isAgree) {
      alert('개인정보보호정책에 동의해주세요.');
      return;
    }
    if (!name) {
      alert('이름을 입력해주세요.');
      return;
    }
    if (!email) {
      alert('이메일을 입력해주세요.');
      return;
    }
    if (!part) {
      alert('서비스 분야를 입력해주세요.');
      return;
    }
    if (!price) {
      alert('예산을 입력해주세요.');
      return;
    }
    if (!phone) {
      alert('전화번호를 입력해주세요.');
      return;
    }
    if (!memo) {
      alert('문의내용을 입력해주세요.');
      return;
    }
    sendEmail();
  };

  const sendEmail = () => {
    console.log(form.current);

    emailjs
      .sendForm(
        'service_w5w0wtj',
        'template_kuyxbd8',
        form.current,
        'a0QHCSltB_cpgo0zR'
      )
      .then(
        (result) => {
          console.log(result.text);
          initForm();
          alert('프로젝트 문의가 완료되었습니다.');
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  var settings = {
    slidesToShow: 1,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 10,
    speed: 18000,
    slidesToScroll: 1,
    cssEase: 'linear',
    arrows: false,
    variableWidth: true,
    draggable: false,
    pauseOnHover: true,
  };

  var settingsOpt = {
    slidesToShow: 2,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 3000,
    speed: 2000,
    slidesToScroll: 1,
    draggable: false,
    adaptiveHeight: false,
    arrows: false,
    centerPadding: '100px',
    centerMode: true,
    variableWidth: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
          centerMode: false,
        },
      },

      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  var settingsOpt2 = {
    slidesToShow: 2,
    autoplay: true,
    infinite: true,
    arrows: false,
    autoplaySpeed: 3000,
    speed: 2000,
    slidesToScroll: 1,
    draggable: false,
    centerPadding: '100px',
    centerMode: false,
    variableWidth: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '60px',
          centerMode: true,
          variableWidth: false,
        },
      },

      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  const showModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  // useEffect(() => {
  //   // DOM 계산 전 size.width가 undefined
  //   Aos.init();
  //   // 타입에러 방지를 위해 사용
  //   if (!size.width) return;

  //   if (size.width < 1280) {
  //     // 브라우저 창 크기가 지정한 사이즈 미만일 때
  //     swiperRef.current?.destroy(true, true);

  //     // 매개변수는 Swiper 인스턴스 파괴 여부, 사용자 지정 스타일 파괴 여부를 의미
  //     // https://swiperjs.com/swiper-api 의 swiper.destroy() 부분 참고!
  //   } else if (size.width >= 1280 && swiperRef.current?.destroyed) {
  //     // 브라우저 창 크기가 지정 사이즈 이상이고 swiper가 해제되었을 때

  //     document.getElementById('main').scrollTop = 0;
  //     swiperRef.current = new SwiperInit('.swiper.main', swiperOption);
  //   }
  // }, [size]);

  return (
    <>
      {isModalOpen ? (
        <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full px-5 bg-black/70'>
          <div className='flex flex-col items-center w-full py-10 pt-10 pb-5 text-center bg-white rounded-xl lg:max-w-[775px] lg:pb-28 lg:pt-28'>
            <img
              className='h-8 lg:h-20'
              src={`${process.env.PUBLIC_URL}/assets/images/ico_wm_logo.svg`}
              alt=''
            />
            <p className='mt-6 text-lg font-bold leading-6 lg:text-3xl lg:leading-10 lg:mt-12'>
              성공의 길을 위해 오픈 준비중입니다.
            </p>
            <p className='text-sm font-medium text-[#8E8E8E] leading-6 lg:text-2xl lg:leading-10'>
              조금만 기다려 주세요 :)
            </p>
            <button
              onClick={() => setIsModalOpen(false)}
              className='mt-5 lg:mt-14 bg-[#123435] py-3 rounded-md w-[225px] text-white lg:py-7 lg:text-2xl'
            >
              확인
            </button>
          </div>
        </div>
      ) : null}

      <div className='' id='visual'>
        <div
          id='waymaker'
          className='relative flex h-[100vh] flex-col justify-center items-center w-full bg-[url("/src/assets/images/m_bg_section_01.png")] lg:bg-[url("/src/assets/images/bg_section_01.png")] bg-no-repeat bg-cover before:content-[""] before:block before:w-full before:h-full before:absolute before:left-0 before:top-0 before:bg-[url("/src/assets/images/bg_section_01_mask.png")]'
        >
          <div className='max-w-[1920px]'>
            <p
              data-aos='fade-up'
              data-aos-duration='1000'
              className='relative z-10 text-[1.75rem] md:text-4xl md:leading-[3rem] leading-9 font-bold text-center text-white lg:font-semibold lg:text-6xl lg:leading-[5.315rem]'
            >
              당신이 가는 길이 가장
              <br className='lg:hidden' /> 빛날 수 있도록,
              <br />
              성공의 길을 열어주는 곳,
              <br className='lg:hidden' /> 웨이메이커.
            </p>
            {!isMobile ? (
              <img
                className='hidden w-full h-[383px] lg:block absolute bottom-0 lg:left-1/2 -translate-x-1/2'
                src={`${process.env.PUBLIC_URL}/assets/images/img_visual_text.svg`}
                alt=''
              />
            ) : null}
            <img
              className='animate-bounce w-[30px] h-[30px] absolute bottom-24 left-1/2 lg:-translate-x-1/2 -ml-[15px] lg:-ml-0'
              src={`${process.env.PUBLIC_URL}/assets/images/ico_arrow_bottom.svg`}
              alt=''
            />
          </div>
        </div>
      </div>
      <div className='flex h-[100vh] items-center justify-center bg-[url("/src/assets/images/bg_slogun.png")] bg-cover bg-no-repeat'>
        <div className='text-center'>
          <img
            data-aos='fade-up'
            data-aos-duration='1000'
            className='relative inline-block w-auto h-auto lg:w-[507px] md:w-[20rem]'
            src={`${process.env.PUBLIC_URL}/assets/images/img_slogun_text.svg`}
            alt=''
          />
          <p
            data-aos='fade-up'
            data-aos-delay='200'
            data-aos-duration='1000'
            className='mt-10 lg:mt-[3.8rem] md:text-[2rem] md:leading-[2.8rem] font-semibold text-[1.38rem] text-white leading-9 lg:font-medium lg:text-[3.15rem] lg:leading-[4.7rem]'
          >
            웨이메이커에서 탄생한
            <br className='lg:hidden' /> 메디컬웨이와 그로잉웨이는
            <br />이 세상 모든 비즈니스
            <br className='lg:hidden' /> 성공활동을 응원합니다.
          </p>
        </div>
      </div>

      <div className='w-full h-auto bg-[url("/src/assets/images/m_bg_consultant.png")] bg-cover bg-no-repeat'>
        <div id='core' className=''>
          <Slider
            className='section-core-slider mt-[6.5rem] lg:mt-20'
            {...settings}
          >
            <div className='px-2 section-core-slider-item'>
              <img
                className='block h-12 lg:h-[190px]'
                src={`${process.env.PUBLIC_URL}/assets/images/scrolling.svg`}
                alt=''
              />
            </div>
            <div className='px-2 section-core-slider-item'>
              <img
                className='block h-12 lg:h-[190px]'
                src={`${process.env.PUBLIC_URL}/assets/images/scrolling.svg`}
                alt=''
              />
            </div>
          </Slider>
        </div>
        <div className='pt-24 pb-32 lg:pt-48'>
          <div className='px-6 lg:flex lg:flex-col lg:max-w-[1920px] lg:mx-auto'>
            <div
              data-aos='fade-up'
              data-aos-duration='1000'
              className='lg:flex lg:w-1/2 lg:justify-end lg:pr-14'
            >
              <div>
                <img
                  className='lg:w-[12.4rem]'
                  src={`${process.env.PUBLIC_URL}/assets/images/ico_consultant_gw.svg`}
                  alt=''
                />
                <p className='font-semibold text-[1.38rem] mt-5 lg:mt-16 leading-9 lg:font-semibold lg:text-[2.85rem] lg:leading-[4.065rem]'>
                  브랜드 가치를 위한 전략적 <br />
                  Solving Point 제시
                </p>
              </div>
            </div>
            <div
              data-aos='fade-up'
              data-aos-delay='200'
              data-aos-duration='1000'
              className='w-full mt-24 lg:mt-48 lg:flex lg:justify-end'
            >
              <div className='lg:w-1/2 lg:pl-14'>
                <img
                  className='lg:w-[12.4rem]'
                  src={`${process.env.PUBLIC_URL}/assets/images/ico_consultant_mw.svg`}
                  alt=''
                />
                <p className='font-semibold text-[1.38rem] mt-5 lg:mt-16 leading-9  lg:font-semibold lg:text-[2.85rem] lg:leading-[4.065rem]'>
                  메디컬 핵심강점을 ‘고객경험’
                  <br />
                  중심으로 전영역에 시스템화
                </p>
              </div>
            </div>
            <div
              data-aos='fade-up'
              data-aos-delay='400'
              data-aos-duration='1000'
              className='self-center mt-28 lg:mt-[21.65rem] lg:w-full lg:text-center'
            >
              <img
                className='lg:w-[12.4rem] lg:inline-block'
                src={`${process.env.PUBLIC_URL}/assets/images/ico_consultant_wm.svg`}
                alt=''
              />
              <p className='font-semibold text-[1.38rem] mt-5 lg:mt-16 leading-9  lg:font-semibold lg:text-[2.85rem] lg:leading-[4.065rem]'>
                성장과 성공을 <br className='lg:hidden' />
                가능케하는 웨이메이커
              </p>
            </div>
          </div>
          <div className='mt-20 max-w-[1920px] lg:mx-auto lg:mt-52'>
            <Slider className='card-slide' {...settingsOpt}>
              <div className='h-[230px] px-5 py-10 lg:px-[3.9rem] lg:py-[4.1rem] bg-white rounded-md shadow-lg lg:rounded-2xl lg:p-16 lg:h-[390px]'>
                <p className='text-xl font-bold leading-6 lg:text-4xl lg:leading-10'>
                  MANAGEMENT
                </p>
                <p className='text-base font-semibold leading-6 lg:text-[1.63rem] lg:leading-10 text-[#8B8B8B] mt-5 break-keep'>
                  기업의 현재와 미래에 실질적인 가치를
                  <br /> 제공하고 고객만족경영 실현을 위해,
                  <br /> 기업의 리더가 의미 있고 효율적인 성장
                  <br /> 전환을 실현할 수 있도록 지원합니다
                </p>
              </div>
              <div className='h-[230px] px-5 py-10 lg:px-[3.9rem] lg:py-[4.1rem] bg-white rounded-md shadow-lg lg:rounded-2xl lg:p-16 lg:h-[390px]'>
                <p className='text-xl font-bold leading-6 lg:text-4xl lg:leading-10'>
                  BRANDING
                </p>
                <p className='text-base font-semibold leading-6 lg:text-[1.63rem] lg:leading-10 text-[#8B8B8B] mt-5  break-keep'>
                  기업의 핵심가치, 비전 및 미션에
                  <br /> 대해 정리하며 이 부분이 비주얼로서
                  <br /> 표현되어 고객과 커뮤니케이션하며
                  <br /> 계속해서 나아갈 수 있도록 돕습니다.
                </p>
              </div>
              <div className='h-[230px] px-5 py-10 lg:px-[3.9rem] lg:py-[4.1rem] bg-white rounded-md shadow-lg lg:rounded-2xl lg:p-16 lg:h-[390px]'>
                <p className='text-xl font-bold leading-6 lg:text-4xl lg:leading-10'>
                  MARKETING
                </p>
                <p className='text-base font-semibold leading-6 lg:text-[1.63rem] lg:leading-10 text-[#8B8B8B] mt-5  break-keep'>
                  브랜딩과 결을 같이하는 마케팅으로
                  <br /> 기업의 본질적 가치를 담아 최적화된
                  <br /> 마케팅으로 기업(브랜드)을 알립니다.
                  <br /> 기억에 남는 마케팅을 고민합니다.
                </p>
              </div>
              <div className='h-[230px] px-5 py-10 lg:px-[3.9rem] lg:py-[4.1rem] bg-white rounded-md shadow-lg lg:rounded-2xl lg:p-16 lg:h-[390px]'>
                <p className='text-xl font-bold leading-6 lg:text-4xl lg:leading-10'>
                  DISCOVERY
                </p>
                <p className='text-base font-semibold leading-6 lg:text-[1.63rem] lg:leading-10 text-[#8B8B8B] mt-5  break-keep'>
                  더 많은 Creativity가 기업에
                  <br /> 제대로 발현될 수 있도록
                  <br /> 끊임없이 새로운 방법을 고안합니다.
                </p>
              </div>
              <div className='h-[230px] px-5 py-10 lg:px-[3.9rem] lg:py-[4.1rem] bg-white rounded-md shadow-lg lg:rounded-2xl lg:p-16 lg:h-[390px]'>
                <p className='text-xl font-bold leading-6 lg:text-4xl lg:leading-10'>
                  CONTINUOUS
                </p>
                <p className='text-base font-semibold leading-6 lg:text-[1.63rem] lg:leading-10 text-[#8B8B8B] mt-5  break-keep'>
                  더 나은 미래를 꿈꿀 수 있도록,
                  <br /> 지속적인 발전을 위한 비즈니스 모델을
                  <br /> 구축 및 운영을 고안합니다.
                </p>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className='relative flex flex-col pt-40 pb-44 lg:pt-60 lg:pb-80 text-center bg-[url("/src/assets/images/bg_section_03.png")] bg-cover bg-no-repeat'>
        <div className='flex justify-center w-full overflow-hidden'>
          <p
            data-aos='fade-up'
            data-aos-duration='1000'
            className='text-3xl font-bold leading-10 text-white md:text-[2.4rem] md:leading-[3rem] lg:text-[4.38rem] lg:font-medium lg:leading-[5.63rem]'
          >
            웨이메이커는 <br className='lg:hidden' />
            귀사의
            <br className='hidden lg:block' /> 전략적
            <br className='lg:hidden' />{' '}
            <em className='text-[#DDFF66] lg:font-semibold'>러닝메이트</em>가
            됩니다.
          </p>
        </div>
        <div className='mt-16 lg:mt-[9.32rem]'>
          <div
            data-aos='fade-right'
            data-aos-delay='200'
            data-aos-duration='1000'
            className='relative overflow-hidden lg:w-1/2 lg:float-left lg:flex lg:justify-end'
          >
            <div className='absolute left-0 w-[95vw] lg:w-[calc(100%-21px)] min-h-40 h-[41vw] lg:h-[460px] bg-[url("/src/assets/images/m_img_section_04_01.png")] lg:bg-[url("/src/assets/images/img_section_04_01.png")] bg-cover lg:bg-contain lg:bg-right bg-no-repeat bg-center rounded-r-3xl lg:rounded-3xl'></div>
            <div className='float-left mt-[46vw] text-left px-11 lg:mt-[525px] lg:pr-[8.2rem]'>
              <p className='text-2xl font-semibold leading-9 text-white lg:text-[2.814rem] lg:font-semibold lg:leading-[4.07rem]'>
                우리는 다양한 실전경험과
                <br />
                전문적 사고로 전략적인 <br />
                접근을 합니다.
              </p>
              <p className='font-medium text-base leading-6 text-[#B3BFBF] mt-7 lg:mt-9 lg:text-[1.56rem] lg:leading-[3.1rem]'>
                유통업계 스타트업의 시작과 성장까지, <br />
                대기업들과의 다양한 프로젝트들로 <br />
                축적된 역량과 노하우로 인프라를 <br />
                구축하였습니다.
              </p>
            </div>
          </div>
          <div
            data-aos='fade-left'
            data-aos-delay='400'
            data-aos-duration='1000'
            className='relative mt-20 overflow-hidden lg:w-1/2 lg:float-left lg:mt-[48rem]'
          >
            <div className='absolute right-0 w-[95vw] lg:w-[calc(100%-21px)] min-h-40 h-[41vw] lg:h-[460px] bg-[url("/src/assets/images/m_img_section_04_02.png")] lg:bg-[url("/src/assets/images/img_section_04_02.png")] bg-cover lg:bg-contain lg:bg-left bg-no-repeat bg-center rounded-l-3xl lg:rounded-l-3xl'></div>
            <div className='float-left mt-[46vw] text-left px-11 lg:mt-[525px] lg:pl-[6.2rem]'>
              <p className='text-2xl font-semibold leading-9 text-white lg:text-[2.814rem] lg:font-semibold lg:leading-[4.07rem]'>
                현재에 안주하지 않고 <br />
                끊임없이 소통하고 새로운
                <br />
                시장을 개척해 나갑니다.
              </p>
              <p className='font-medium text-base leading-6 text-[#B3BFBF] mt-7 lg:mt-9 lg:text-[1.56rem] lg:leading-[3.1rem]'>
                약 50개 이상의 프로젝트 수행 경험과 <br />
                각 전문 PM 들의 평균 경력은 <br />
                10년에 달하는 국내 전문 브랜딩 <br />
                전략 컨설팅 팀입니다
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        id='medical'
        className='w-full h-auto bg-[url("/src/assets/images/bg_section_05.png")] bg-cover bg-no-repeat'
      >
        <div className='lg:relative lg:pt-[20.65rem] max-w-[1920px] mx-auto'>
          <div className='lg:hidden'>
            <img
              className='w-full'
              src={`${process.env.PUBLIC_URL}/assets/images/m_img_section_05.png`}
              alt=''
            />
          </div>
          <img
            className='absolute top-0 hidden lg:inline-block -right-4 lg:w-[40%]'
            src={`${process.env.PUBLIC_URL}/assets/images/img_section_05.png`}
            alt=''
          />
          <div className='px-5 mt-6 text-center lg:mt-0 pb-28 lg:pb-[20rem] lg:text-left max-w-[1560px] mx-auto'>
            <div className=''>
              <img
                data-aos='fade-up'
                data-aos-duration='1000'
                className='inline-block lg:w-[262px]'
                src={`${process.env.PUBLIC_URL}/assets/images/ico_medicalway_text.svg`}
                alt=''
              />
              <p
                data-aos='fade-up'
                data-aos-delay='200'
                data-aos-duration='1000'
                className='w-full lg:w-auto mt-6 md:text-[2.5rem] md:leading-[3rem] text-3xl font-semibold leading-10 lg:text-[4.4rem] lg:leading-[5rem] lg:mr-[40%] break-keep'
              >
                메디컬 전문 컨설턴트, <br className='lg:hidden' />
                메디컬웨이
              </p>
              <p className='text-base font-semibold leading-6 text-[#8B8B8B] mt-9 lg:font-semibold lg:text-2xl lg:leading-10 lg:mt-14 break-keep'>
                단순한 오픈 세팅이 아닌 <br className='lg:hidden' />
                포지셔닝 환경을 정확하게 파악하고,
                <br />
                환자 니즈를 깊이 이해하는 든든한 <br className='lg:hidden' />
                메디컬 경영 컨설턴트입니다
              </p>
              <button onClick={showModal} className='inline-block mt-9'>
                <span className='text-sm font-semibold leading-6 text-[#123435] lg:text-[1.9rem]'>
                  메디컬 웨이
                </span>
                <img
                  className='inline-block ml-2 lg:align-top lg:mt-1'
                  src={`${process.env.PUBLIC_URL}/assets/images/ico_arrow_right_black.svg`}
                  alt=''
                />
              </button>
            </div>
            <div className='mt-32 lg:mt-44'>
              <p className='text-xl font-bold leading-6 lg:text-4xl lg:font-bold'>
                ABOUT MW
              </p>
              <p className='text-base font-semibold leading-6 text-[#8B8B8B] mt-5 lg:text-[1.64rem] lg:leading-10 lg:mt-7'>
                각기 다른 개원조건, 최적화된
                <br />
                개원전략 수립으로 경영 성과를
                <br />
                견고하게 만들어갑니다.
              </p>
              <button
                onClick={showModal}
                className='inline-block mt-5 lg:mt-10'
              >
                <span className='text-sm font-semibold leading-6 text-[#123435] lg:text-[1.9rem]'>
                  더보기
                </span>
                <img
                  className='inline-block ml-2 lg:align-top lg:mt-1'
                  src={`${process.env.PUBLIC_URL}/assets/images/ico_arrow_right_black.svg`}
                  alt=''
                />
              </button>
            </div>
            <div className='mt-32 lg:mt-36'>
              <p className='text-xl font-bold leading-6 lg:text-4xl lg:font-bold'>
                OUR WORK
              </p>
              <p className='text-base font-semibold leading-6 text-[#8B8B8B] mt-5 lg:text-[1.64rem] lg:leading-10 lg:mt-7'>
                메디컬웨이와 함께걸어온
                <br />
                다양한 병의원들을
                <br />
                만나보실수 있습니다.
              </p>
              <button
                onClick={showModal}
                className='inline-block mt-5 lg:mt-10'
              >
                <span className='text-sm font-semibold leading-6 text-[#123435] lg:text-[1.9rem]'>
                  더보기
                </span>
                <img
                  className='inline-block ml-2 lg:align-top lg:mt-1'
                  src={`${process.env.PUBLIC_URL}/assets/images/ico_arrow_right_black.svg`}
                  alt=''
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id='growing'
        className='w-full bg-[#123435] pt-40 pb-28 lg:pt-64 lg:pb-64'
      >
        <div className=' px-5 max-w-[1560px] mx-auto'>
          <img
            data-aos='fade-up'
            data-aos-duration='1000'
            className='lg:w-[278px]'
            src={`${process.env.PUBLIC_URL}/assets/images/ico_growingway_text.svg`}
            alt=''
          />
          <p
            data-aos='fade-up'
            data-aos-delay='200'
            data-aos-duration='1000'
            className='text-3xl font-semibold leading-10 md:text-[3rem] md:leading-[3.5rem] text-white mt-7 lg:mt-16 lg:text-[3.45rem] break-keep lg:leading-[4.7rem]'
          >
            기업의 고유성을 살려 <br className='lg:hidden' />
            차별화된 솔루션으로
            <br className='lg:hidden' /> 매출성장으로, <br />
            <em className='text-[#DDFF66]'>
              그로잉웨이는 반드시
              <br className='lg:hidden' /> 당신의 성장의 길을
              <br className='lg:hidden' /> 만듭니다.
            </em>
          </p>
          <p className='text-base font-medium leading-6 mt-7 text-[#B3BFBF] lg:mt-14 lg:text-2xl lg:leading-10'>
            새로운 패러다임에 맞춰 클라이언트가 <br className='lg:hidden' />
            원하는 브랜딩, 디자인, 컨텐츠 광고 등 <br className='lg:hidden' />
            디지털 마케팅의
            <br className='hidden lg:block' /> 모든 것들 컨설팅 합니다.{' '}
            <br className='lg:hidden' />
            그로잉웨이는 반드시 당신의 <br className='lg:hidden' />
            성장의 길을 만듭니다.
          </p>
          <button onClick={showModal} className='inline-block mt-8 lg:mt-24'>
            <span className='text-sm font-semibold leading-6 text-white lg:text-3xl lg:leading-10'>
              그로잉웨이
            </span>
            <img
              className='inline-block ml-2 lg:align-top lg:mt-3'
              src={`${process.env.PUBLIC_URL}/assets/images/ico_arrow_right_white.svg`}
              alt=''
            />
          </button>
        </div>
        <div className='mt-16 lg:mt-[8.4rem]'>
          <div className='max-w-[1560px] mx-auto'>
            <Slider className=' growingway-slide lg:h-auto' {...settingsOpt2}>
              <div className='px-3 lg:px-[20px] lg:w-[760px] lg:h-[492px]'>
                <img
                  className='w-full h-full'
                  src={`${process.env.PUBLIC_URL}/assets/images/img_growing_slide_01.png`}
                  alt=''
                />
              </div>
              <div className='px-3 lg:px-[20px] lg:w-[760px] lg:h-[492px]'>
                <img
                  className='w-full h-full'
                  src={`${process.env.PUBLIC_URL}/assets/images/img_growing_slide_02.png`}
                  alt=''
                />
              </div>
              <div className='px-3 lg:px-[20px]  lg:w-[760px] lg:h-[492px]'>
                <img
                  className='w-full h-full'
                  src={`${process.env.PUBLIC_URL}/assets/images/img_growing_slide_03.png`}
                  alt=''
                />
              </div>
              <div className='px-3 lg:px-[20px]  lg:w-[760px] lg:h-[492px]'>
                <img
                  className='w-full h-full'
                  src={`${process.env.PUBLIC_URL}/assets/images/img_growing_slide_04.png`}
                  alt=''
                />
              </div>
              <div className='px-3 lg:px-[20px]] lg:w-[760px] lg:h-[492px]'>
                <img
                  className='w-full h-full'
                  src={`${process.env.PUBLIC_URL}/assets/images/img_growing_slide_05.png`}
                  alt=''
                />
              </div>
              <div className='px-3 lg:px-[20px] lg:w-[760px] lg:h-[492px]'>
                <img
                  className='w-full h-full'
                  src={`${process.env.PUBLIC_URL}/assets/images/img_growing_slide_06.png`}
                  alt=''
                />
              </div>
            </Slider>
          </div>
        </div>
        <div className='mt-16 px-5 max-w-[1560px] mx-auto lg:flex lg:mt-32'>
          <div className=' lg:w-1/2 lg:pl-36'>
            <p className='text-xl font-bold leading-6 text-white lg:text-4xl lg:leading-[2.7rem]'>
              ABOUT GW
            </p>
            <p className='text-base font-medium leading-6 text-[#b3bfbf] mt-5 lg:text-[1.625rem] lg:leading-[2.5rem] lg:mt-7 lg:h-[8.3rem]'>
              그로잉웨이는 브랜드 전략 컨설팅으로
              <br />
              사업의 ‘본질(브랜딩)’을 올바르게
              <br />
              ‘인식(마케팅)’시킵니다.
            </p>
            <button
              onClick={showModal}
              className='inline-block mt-5 lg:text-[1.875rem] leading-[2.5rem] lg:mt-10'
            >
              <span className='text-sm font-semibold leading-6 text-white lg:text-[1.875rem] leading-[2.5rem]'>
                더보기
              </span>
              <img
                className='inline-block ml-2 lg:align-top lg:mt-3'
                src={`${process.env.PUBLIC_URL}/assets/images/ico_arrow_right_white.svg`}
                alt=''
              />
            </button>
          </div>
          <div className='mt-16 lg:mt-0 lg:w-1/2 lg:pl-36'>
            <p className='text-xl font-bold leading-6 text-white lg:text-4xl lg:leading-[2.7rem]'>
              OUR WORK
            </p>
            <p className='text-base font-medium leading-6 text-[#b3bfbf] mt-5 lg:text-[1.625rem] lg:leading-[2.5rem] lg:mt-7 lg:h-[8.3rem]'>
              그로잉웨이와 함께 걸어온 다양한
              <br />
              브랜드기업들을 만나보실수 있습니다.
            </p>
            <button onClick={showModal} className='inline-block mt-5 lg:mt-10'>
              <span className='text-sm font-semibold leading-6 text-white lg:text-[1.875rem] leading-[2.5rem]'>
                더보기
              </span>
              <img
                className='inline-block ml-2 lg:align-top lg:mt-3'
                src={`${process.env.PUBLIC_URL}/assets/images/ico_arrow_right_white.svg`}
                alt=''
              />
            </button>
          </div>
        </div>
      </div>

      <div className='text-center py-40 max-w-[1280px] mx-auto lg:flex justify-between items-center lg:px-8'>
        <div>
          <p className='text-center lg:text-left'>
            <img
              className='inline-block lg:w-[527px]'
              src={`${process.env.PUBLIC_URL}/assets/images/ico_section_06_text.svg`}
              alt=''
            />
          </p>
          <p className='font-semibold text-lg lg:text-left leading-9 text-[#123435] text-center mt-4 lg:text-2xl lg:leading-6 lg:mt-10'>
            성공의 길을 열어주는 웨이메이커
          </p>
        </div>
        <button
          onClick={showModal}
          className='inline-block bg-[#123435] px-9 py-5 rounded-xl mt-10 lg:mt-0 lg:px-14 lg:py-9'
        >
          <span className='text-base font-medium leading-6 text-white lg:text-[1.625rem] lg:leading-7'>
            회사소개서 다운로드
          </span>
          <img
            className='inline-block mt-1 ml-2 align-top lg:w-[22px] lg:ml-3'
            src={`${process.env.PUBLIC_URL}/assets/images/ico_download.svg`}
            alt=''
          />
        </button>
      </div>

      <div id='contact' className='relative bg-[#EEEFF2]'>
        <div className='max-w-[1920px]  py-36 mx-auto lg:bg-[url("/src/assets/images/bg_section_07.svg")] bg-no-repeat bg-right-top'>
          <div className=' lg:max-w-[1280px] lg:mx-auto px-8'>
            <img
              className='absolute top-0 right-0 lg:hidden'
              src={`${process.env.PUBLIC_URL}/assets/images/m_bg_section_07.svg`}
              alt=''
            />
            <div className='relative z-10 max-w-[670px]'>
              <p>
                <img
                  className='lg:w-[320px]'
                  src={`${process.env.PUBLIC_URL}/assets/images/ico_section_07_text.svg`}
                  alt=''
                />
              </p>
              <p className='text-lg font-semibold leading-6 text-[#2c2c2c] mt-5 lg:text-2xl lg:mt-8'>
                웨이메이커는 귀사의 비즈니스 성장을 <br className='lg:hidden' />
                도울 준비가 되어있습니다.
              </p>
              <div className='text-center mt-14 lg:mt-24'>
                <form ref={form}>
                  <div className='flex flex-col lg:flex-wrap lg:flex-row lg:justify-between'>
                    <input
                      type='text'
                      className='mt-5 px-3 placeholder:text-[#2c2c2c] font-semibold text-[#2c2c2c] h-12 bg-transparent border-b-2 border-[#cbcbcb] lg:w-[calc(50%-23px)] lg:text-lg rounded-none focus:outline focus:outline-[2px] focus:outline-[#123435]'
                      placeholder='회사명'
                      value={company}
                      name='company'
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    <input
                      type='text'
                      className='mt-5 px-3 placeholder:text-[#2c2c2c] font-semibold text-[#2c2c2c] h-12 bg-transparent border-b-2 border-[#cbcbcb] lg:w-[calc(50%-23px)] lg:text-lg rounded-none focus:outline focus:outline-[2px] focus:outline-[#123435]'
                      placeholder='이름'
                      value={name}
                      name='name'
                      onChange={(e) => setName(e.target.value)}
                    />
                    <input
                      type='text'
                      className='mt-5 px-3 placeholder:text-[#2c2c2c] font-semibold text-[#2c2c2c] h-12 bg-transparent border-b-2 border-[#cbcbcb] lg:w-[calc(50%-23px)] lg:text-lg rounded-none focus:outline focus:outline-[2px] focus:outline-[#123435]'
                      placeholder='이메일'
                      value={email}
                      name='email'
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                      type='text'
                      className='mt-5 px-3 placeholder:text-[#2c2c2c] font-semibold text-[#2c2c2c] h-12 bg-transparent border-b-2 border-[#cbcbcb] lg:w-[calc(50%-23px)] lg:text-lg rounded-none focus:outline focus:outline-[2px] focus:outline-[#123435]'
                      placeholder='연락처'
                      value={phone}
                      name='phone'
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <input
                      type='text'
                      className='mt-5 px-3 placeholder:text-[#2c2c2c] font-semibold text-[#2c2c2c] h-12 bg-transparent border-b-2 border-[#cbcbcb] lg:w-[calc(50%-23px)] lg:text-lg rounded-none focus:outline focus:outline-[2px] focus:outline-[#123435]'
                      placeholder='서비스 분야'
                      value={part}
                      name='part'
                      onChange={(e) => setPart(e.target.value)}
                    />
                    <input
                      type='text'
                      className='mt-5 px-3 placeholder:text-[#2c2c2c] font-semibold text-[#2c2c2c] h-12 bg-transparent border-b-2 border-[#cbcbcb] lg:w-[calc(50%-23px)] lg:text-lg rounded-none focus:outline focus:outline-[2px] focus:outline-[#123435]'
                      placeholder='예산'
                      value={price}
                      name='price'
                      onChange={(e) => setPrice(e.target.value)}
                    />
                    <textarea
                      placeholder='프로젝트 내용을 간단하게 설명해주세요.'
                      className='p-5 mt-12 lg:mt-14 w-full placeholder:text-[#2c2c2c] font-semibold text-[#2c2c2c] h-36 outline-none bg-transparent border-2 border-[#cbcbcb] lg:text-lg rounded-none focus:outline focus:outline-[2px] focus:outline-[#123435]'
                      value={memo}
                      name='memo'
                      onChange={(e) => setMemo(e.target.value)}
                    ></textarea>
                  </div>
                  <div className='flex items-center mt-4'>
                    <label
                      className='relative flex items-center rounded-full cursor-pointer pr--3'
                      htmlFor='react'
                      data-ripple-dark='true'
                    >
                      <input
                        id='react'
                        name='isAgree'
                        type='radio'
                        checked={isAgree}
                        onClick={() => setIsAgree(true)}
                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border-2 border-[#cbcbcb] text-[#123435] transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full  before:opacity-0 before:transition-opacity checked:border-[#cbcbcb] checked:before:bg-[#123435] hover:before:opacity-10"
                      />
                      <div className='pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-[#123435] opacity-0 transition-opacity peer-checked:opacity-100'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-3.5 w-3.5'
                          viewBox='0 0 16 16'
                          fill='currentColor'
                        >
                          <circle
                            data-name='ellipse'
                            cx='8'
                            cy='8'
                            r='8'
                          ></circle>
                        </svg>
                      </div>
                    </label>
                    <label
                      className='ml-2 font-medium cursor-pointer select-none lg:text-lg'
                      htmlFor='react'
                    >
                      개인정보보호정책에 동의합니다.
                    </label>
                  </div>
                  <a
                    href='#'
                    onClick={onClickSend}
                    className='mt-7 lg:mt-9 py-6 w-full inline-block text-lg font-medium leading-5 text-white rounded-full bg-[#123435]'
                  >
                    프로젝트 문의하기
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
