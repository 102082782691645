import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';

export function useScroll() {
  const [scrollY, setScrollY] = useState(0);
  const location = useLocation();

  const listener = () => {
    setScrollY(window.pageYOffset);
  };

  const delay = 15;

  useEffect(() => {
    window.addEventListener('scroll', debounce(listener, delay));

    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  return {
    scrollY,
  };
}
