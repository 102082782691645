import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';

import { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Footer from './components/Footer';

function App() {
  const [activeIdx, setActiveIdx] = useState(0);
  useEffect(() => {
    Aos.init({ offset: 100, duration: 700, easing: 'ease-out-quad', once: !0 });
    window.addEventListener('load', Aos.refresh);
  }, []);
  const changeActiveIdx = (idx) => {
    setActiveIdx(idx);
  };
  return (
    <div className='h-full App'>
      <BrowserRouter>
        <Header className='header' activeIdx={activeIdx} />
        <Routes>
          <Route
            path='/'
            element={<Home activeIdxFunc={changeActiveIdx} />}
          ></Route>

          {/* <Route path="/product/*" element={<Product />}></Route> */}
          {/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
          {/* <Route path="*" element={<NotFound />}></Route> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
