function Footer() {
  return (
    <footer className='bg-[#112626] bg-no-repeat bg-right-bottom lg:bg-[url("/src/assets/images/bg_footer.svg")] px-8 pt-8 pb-20'>
      <div className='lg:max-w-[1408px] mx-auto'>
        <ul className='flex items-center justify-end'>
          <li>
            <a
              href='https://www.youtube.com/channel/UCvmY4tcxKOFlqDCAdj98JLA/featured'
              target='_blank'
              className='inline-block align-middle'
            >
              <img
                className='lg:w-[40px]'
                src={`${process.env.PUBLIC_URL}/assets/images/ico_youtube.svg`}
                alt=''
              />
            </a>
          </li>
          <li className='ml-5'>
            <a
              href='https://www.instagram.com/waymaker_offical/'
              target='_blank'
              className='inline-block align-middle'
            >
              <img
                className='lg:w-[36px]'
                src={`${process.env.PUBLIC_URL}/assets/images/ico_instagram.svg`}
                alt=''
              />
            </a>
          </li>
          <li className='ml-5'>
            <a
              href='https://blog.naver.com/waymakercom'
              target='_blank'
              className='inline-block align-middle'
            >
              <img
                className='lg:w-[39px]'
                src={`${process.env.PUBLIC_URL}/assets/images/ico_blog.svg`}
                alt=''
              />
            </a>
          </li>
        </ul>
        <div className='mt-12 lg:-mt-8'>
          <p>
            <img
              className='lg:w-[170px]'
              src={`${process.env.PUBLIC_URL}/assets/images/ico_footer_text.svg`}
              alt=''
            />
          </p>
          <p className='mt-5 text-xs font-medium leading-3 text-white lg:text-sm lg:leading-5 lg:mt-4'>
            웨이메이커 대표자 : 김민영
          </p>
          <p className='mt-2 text-xs font-medium leading-3 text-white lg:text-sm lg:leading-5'>
            사업자 등록번호 : 613-30-91702
          </p>
        </div>
        <ul className='mt-11 lg:flex lg:items-start lg:mt-12'>
          <li className='lg:mr-32'>
            <p className='text-xs font-bold leading-5 text-white/50 lg:text-sm lg:leading-8'>
              phone
            </p>
            <p className='text-sm font-normal leading-5 text-white lg:text-xl lg:leading-8'>
              02-555-3205
            </p>
          </li>
          <li className='mt-7 lg:mt-0 lg:mr-32'>
            <p className='text-xs font-bold leading-5 text-white/50 lg:text-sm lg:leading-8'>
              Email
            </p>
            <p className='text-sm font-normal leading-5 text-white lg:text-xl lg:leading-8'>
              info@way-maker.co.kr
            </p>
          </li>
          <li className='mt-7 lg:mt-0'>
            <p className='text-xs font-bold leading-5 text-white/50 lg:text-sm lg:leading-8'>
              address
            </p>
            <p className='text-sm font-normal leading-5 text-white lg:text-xl lg:leading-8'>
              서울 강남구 테헤란로 410 금강타워 11F
            </p>
          </li>
        </ul>
        <p className='text-xs font-normal leading-4 mt-7 text-white/50 lg:text-lg lg:mt-16'>
          Copyright 2023 waymaker. all rights reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer;
